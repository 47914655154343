import React, { Component, useState } from "react";
import "../App.css";
import ContactHero from "./ContactHero/ContactHero";
import ContactUs from "./ContactUs/ContactUs";
export default function Contact({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id=" ContactHero ">
      <ContactHero />
      <ContactUs />
    </div>
  );
}
