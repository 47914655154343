import './Gallery.css';
import { Rate } from 'antd';

import { Link } from 'react-router-dom';
import img11 from '../images/img11.jpg';
import img12 from '../images/img12.jpg';
import img13 from '../images/img13.jpg';
import img14 from '../images/img14.jpg';
export default function Gallery() {
  const data = [
    {
      image: img11,
      name: 'BAPTISM',
      content: `For Catholics, the Sacrament of Baptism is the first step in a lifelong journey of commitment and discipleship. Through baptism we are freed from the darkness of our world, we become sons and daughters of God and are embraced into the family of the Church. Baptism removes the guilt and effects of Original Sin and incorporates the baptised into the Church, the Mystical Body of Christ on earth. Catholic families are encouraged to baptise their children soon after birth, however sometimes people are baptised later, or even as adults. Whether we are baptised as infants or adults, Baptism is the Church's way of celebrating and enacting the embrace of God.`,
    },
    {
      image: img12,
      name: 'RECONCILIATION',
      content: `Reconciliation is a sacrament which can be celebrated repeatedly throughout one's life, and indeed Catholics are encouraged to receive this sacrament frequently. At a minimum, Catholics must make a confession of sins when they are conscious of mortal sin before the reception of Holy Communion. The precept of the Church says that Catholics ought to confess their sins in the sacrament of Confession at least once a year during the Easter Season.
        `,
    },
    {
      image: img13,
      name: 'FIRST HOLY COMMUNION',
      content: `The sacrament of Eucharist brings to completion the Christian process of initiation. In this sacrament we remember what Jesus did for us in his life, death and resurrection. We remember particularly the Last Supper, that final meal Jesus shared with his disciples. At that meal Jesus gave us the Eucharist so that we could remember him in a special way. When we receive Communion, we believe that we receive the person of Jesus into our very beings. We become one with him, and we become one with each other. As a community we become 'the body of Christ'.`,
    },
    {
      image: img14,
      name: 'CONFIRMATION',
      content: `The Sacrament of Confirmation is the second of the three sacraments of initiation. Confirmation is a Catholic Sacrament of mature Christian commitment and a deepening of baptismal gifts. Confirmation perfects our baptism and brings us the graces of the Holy Spirit that were granted to the Apostles on Pentecost Sunday. It is most often associated with the gifts of the Holy Spirit. The Sacrament of Confirmation completes the sacrament of Baptism. If Baptism is the sacrament of re-birth to a new and supernatural life, Confirmation is the sacrament of maturity and coming of age. Confirmation is a sacrament of empowerment. It gives the strength to follow Jesus and to become involved in the mission which Jesus left to the Church.`,
    },
  ];
  return (
    <div className="main-gallery-page ">
      <div
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className="container"
      >
        <div className=" Gallery-page-heading text-center">
          <h1>FAITH & SACRAMENTS</h1>
        </div>
        <div className=" Gallery-page-para text-center pb-3">
          <p className="">
            Celebrated worthily in faith, the sacraments confirms
            <br /> the grace that they signify.
          </p>
        </div>

        <div className="row g-0 p-0 m-0">
          {data.map((e) => (
            <div
              key={e + Math.random()}
              className="col-lg-3 col-md-6 col-sm-6 my-md-0 my-2"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="300"
            >
              <div class="mx-2 img-inbox">
                <img
                  loading="lazy"
                  className="img-fluid  d-flex justify-content-between"
                  src={`${e.image}`}
                />
                <div className="gallery-image-flip-content">
                  <div className="name-box">{e.name}</div>
                  <div className="img-content-box">{e.content}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
