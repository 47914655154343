import React, { Component } from "react";
import img5 from "../images/img5.png";
import "./SermonServices.css";
import { Carousel } from "antd";
import { BsClock } from "react-icons/bs";
import { GrLocationPin } from "react-icons/gr";
import img25 from "../images/img25.jpg";
import img26 from "../images/img26.jpg";
import img27 from "../images/img27.jpg";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { Input, Button, Drawer, Form, message } from "antd";
import { Link } from "react-scroll";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
const SermonServices = ({ setNewMessage, setModalVisible }) => {
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const data = [
    {
      type: "20 JULY",
      name: "Upcoming Event",
      sermons: "WATCH AND LISTEN TO OUR SERMONS",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      location: (
        <div className="SermonServices-page-location-icon">
          <BsClock />
          <span className="SermonServices-page-location-content m-md-4">
            Friday 23:39 IST <br></br>Saturday 11:20 ISD
          </span>
        </div>
      ),
      cash: (
        <div className="SermonServices-page-name-icon">
          <GrLocationPin />
          <span className="SermonServices-page-name-content m-md-4">
            No 233 Main St. New York,
            <br /> United States
          </span>
        </div>
      ),
      // heart: (
      //   <span className="career-page-heart-icon">
      //     <i class="fa fa-heart"></i>
      //   </span>
      // ),
      apply: (
        <span className="SermonServices-btn ">
          <Button type="primary ">Register</Button>
        </span>
      ),
      img: img25,
    },
    {
      type: "20 JULY",
      name: "Upcoming Event",
      sermons: "WATCH AND LISTEN TO OUR SERMONS",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      location: (
        <div className="SermonServices-page-location-icon">
          <BsClock />
          <span className="SermonServices-page-location-content m-md-4">
            Friday 23:39 IST <br></br>Saturday 11:20 ISD
          </span>
        </div>
      ),
      cash: (
        <div className="SermonServices-page-name-icon">
          <GrLocationPin />
          <span className="SermonServices-page-name-content m-md-4">
            No 233 Main St. New York,
            <br /> United States
          </span>
        </div>
      ),
      // heart: (
      //   <span className="career-page-heart-icon">
      //     <i class="fa fa-heart"></i>
      //   </span>
      // ),
      apply: (
        <span className="SermonServices-btn ">
          <Button type="primary ">Register</Button>
        </span>
      ),
      img: img26,
    },
    {
      type: "20 JULY",
      name: "Upcoming Event",
      sermons: "WATCH AND LISTEN TO OUR SERMONS",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      location: (
        <div className="SermonServices-page-location-icon">
          <BsClock />
          <span className="SermonServices-page-location-content m-md-4">
            Friday 23:39 IST <br></br>Saturday 11:20 ISD
          </span>
        </div>
      ),
      cash: (
        <div className="SermonServices-page-name-icon">
          <GrLocationPin />
          <span className="SermonServices-page-name-content m-md-4">
            No 233 Main St. New York, <br />
            United States
          </span>
        </div>
      ),
      // heart: (
      //   <span className="career-page-heart-icon">
      //     <i class="fa fa-heart"></i>
      //   </span>
      // ),
      apply: (
        <span className="SermonServices-btn ">
          <Button className="" type="primary ">
            Register
          </Button>
        </span>
      ),
      img: img27,
    },
  ];
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const content = (e) =>
    data.map((e) => (
      <div className="col-12 d-lg-flex ">
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="content-pack col-lg-4 my-3"
        >
          <div className="mx-2 px-5 in-box">
            <div className=" mt-3">
              <div className="">
                <div className="mb-0 SermonServices-type text-end">
                  {e.type}
                </div>
                <div className=" SermonServices-name">{e.name}</div>
                <div className=" mt-3 SermonServices-sermons">{e.sermons}</div>
                <div className="mt-3 SermonServices-content">{e.content}</div>
                <div className="mt-5 SermonServices-page-name-icon">
                  {e.location}
                </div>
                <div className="mt-4 SermonServices-page-name-icon">
                  {e.cash}
                </div>
                <div className="text-start mt-5">
                  <span className=" SermonServices-btn text-start ">
                    {e.apply}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="SermonServices-img-box col-lg-8 col-md-12 col-sm-12 ">
          <img
            className="px-3 col-12"
            // data-aos="fade-up"
            // data-aos-delay="200"
            src={`${e.img}`}
          />
        </div>
      </div>
    ));

  return (
    <div>
      <main className="side-SermonServices-page" id="nature">
        <section className="SermonServices-page container">
          <div className=" SermonServices-page-para">
            <p className=" text-center">UPCOMING SERMONS</p>
          </div>
          <div className=" SermonServices-page-heading text-center">
            <h1>
              JOIN US AND BECOME PART
              <br /> OF SOMETHING GREAT
            </h1>
          </div>
          <Carousel autoplay={true} afterChange={onChange} fade={true}>
            {content()}
          </Carousel>
        </section>
      </main>
    </div>
  );
};

export default SermonServices;
