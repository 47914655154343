import React, { Component, useState } from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";

import "./Ourbloglast.css";

const Ourbloglast = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [position, setPosition] = useState("");
  const handleOk = () => {
    setModalVisible(false);
  };
  const data = [
    {
      head: "RELATIONSHIP",
      name: "THE BEST WAY TO INSPIRE PEOPLE",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      address: (
        <div>
          By Mathew Johnson <br />
          Tuesday 13 May, 2021
        </div>
      ),
    },
    {
      head: "RELATIONSHIP",
      name: "HOW TO SHOW COMPASSION",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      address: (
        <div>
          By Mathew Johnson <br />
          Tuesday 13 May, 2021
        </div>
      ),
    },
    {
      head: "RELATIONSHIP",
      name: "THE BIBLICAL PURPOSE OF MONEY",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      address: (
        <div>
          By Mathew Johnson <br />
          Tuesday 13 May, 2021
        </div>
      ),
    },
    {
      head: "RELATIONSHIP",
      name: "THE BEST WAY TO INSPIRE PEOPLE",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      address: (
        <div>
          By Mathew Johnson <br />
          Tuesday 13 May, 2021
        </div>
      ),
    },
    {
      head: "RELATIONSHIP",
      name: "WHAT IT MEANS TO BE A DISCIPLE",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      address: (
        <div>
          By Mathew Johnson <br />
          Tuesday 13 May, 2021
        </div>
      ),
    },
    {
      head: "RELATIONSHIP",
      name: "WHAT IT MEANS TO BELIEVE",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      address: (
        <div>
          By Mathew Johnson <br />
          Tuesday 13 May, 2021
        </div>
      ),
    },
    {
      head: "RELATIONSHIP",
      name: "THE MODERN CHURCH IN 2022",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      address: (
        <div>
          By Mathew Johnson <br />
          Tuesday 13 May, 2021
        </div>
      ),
    },
  ];
  return (
    <div>
      <main className="side-Ourbloglast-page" id="nature">
        <section
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="Ourbloglast-page container"
        >
          <div className=" Ourbloglast-page-heading text-center">
            <h1>ALL BLOG POSTS</h1>
          </div>
          <div className="d-lg-flex "></div>
          <div className="row g-3  content-pack">
            {data.map((e) => (
              <div key={e + Math.random()} className="col-xl-3 col-md-6">
                <div className="mx-2 p-5 in-Ourbloglast-box">
                  <div className="d-flex justify-content-between mt-3">
                    <div className="">
                      <div className=" Ourbloglast-head">{e.head}</div>
                      <div className=" Ourbloglast-name">{e.name}</div>
                      <div className=" Ourbloglast-content">{e.content}</div>
                      <div className=" Ourbloglast-address">{e.address}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};
export default Ourbloglast;
