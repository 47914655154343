import React, { Component, useState } from "react";
import "../App.css";
import RelationHero from "./RelationHero/RelationHero";
export default function Relation({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="sermons">
      <RelationHero />
    </div>
  );
}
