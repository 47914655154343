import React from "react";
import { Button } from "antd";
// import video from "../videos/video.mp4";
import "./RelationHero.css";
import img31 from "../images/img31.jpg";
import img32 from "../images/img32.png";
import CountUp from "react-countup";
import { AiOutlineMinus } from "react-icons/ai";
const RelationHero = () => (
  <div>
    <main className="main-RelationHero-page" id="nature">
      <div className="RelationHero-page">
        {" "}
        <p
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          className=" text-center"
        >
          RELATIONSHIP{" "}
        </p>
      </div>
      <div className="RelationHero-page">
        <h1
          className=" text-center"
          data-aos=" fade-right"
          data-aos-easing="ease-in-sine"
        >
          HOW TO SHOW COMPASSION
        </h1>
      </div>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className="RelationHero-page-para "
      >
        <p
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          className=" text-center"
        >
          13 May, 2018 By Mathew Johnson
        </p>
      </div>
      <section className="main-RelationHero-head-page container"></section>
      <div className="container col-md-6">
        {" "}
        <div className="main-RelationHero-head-page-heading">
          <h1>LOREM IPSUM DOLOR SIT AMET</h1>
        </div>
        <div className="main-RelationHero-head-page-para1">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation. Ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Non tellus orci ac auctor augue mauris augue neque
            gravida.
          </p>
        </div>
        <div className="main-RelationHero-head-page-para2">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation. Ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Non tellus orci ac auctor augue mauris augue neque
            gravida.
          </p>
        </div>
        <div className="mx-2">
          <img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            data-aos-delay="200"
            className="img-fluid"
            src={img31}
          ></img>
        </div>
        <div className="main-RelationHero-head-page-heading1">
          <h1>LOREM IPSUM DOLOR SIT AMET</h1>
        </div>
        <div className="main-RelationHero-head-page-para3">
          <p>
            Aenean vel elit scelerisque mauris. Imperdiet sed euismod nisi porta
            lorem mollis aliquam. Quis vel eros donec ac odio tempor orci
            dapibus ultrices. Elementum eu facilisis sed odio morbi. Nam at
            lectus urna duis convallis convallis tellus id. Feugiat pretium nibh
            ipsum consequat nisl vel. Elementum pulvinar etiam non quam lacus.
            Sit amet porttitor eget.
          </p>
        </div>
        <div className="d-flex justify-content-between ">
          <div>
            <img className="img-fluid mx-1" src={img32}></img>
          </div>
          <div className="main-RelationHero-head-page-para4">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui
              faucibus in ornare quam viverra orci sagittis eu volutpat faucibus
              in ornare quam viverra orci sagittis eu volutpat.
            </p>
          </div>
        </div>
        <div className="main-RelationHero-head-page-para5">
          <p>
            Aenean vel elit scelerisque mauris. Imperdiet sed euismod nisi porta
            lorem mollis aliquam. Quis vel eros donec ac odio tempor orci
            dapibus ultrices. Elementum eu facilisis sed odio morbi. Nam at
            lectus urna duis convallis convallis tellus id. Feugiat pretium nibh
            ipsum consequat nisl vel. Elementum pulvinar etiam non quam lacus.
            Sit amet porttitor eget.
          </p>
        </div>
        <div className="main-RelationHero-head-page-heading1">
          <h1>LOREM IPSUM DOLOR SIT AMET</h1>
        </div>
        <div className="main-RelationHero-head-page-para6">
          <p>
            We both celebrate and challenge the culture around us as we orient
            our lives around Jesus. We want to serve the world around us. It’s
            why we support mission work all across the globe Regardless of what
            your next step in faith is, we want to help you take that next step
            Our church is10% of our annual income to
          </p>
        </div>
        <div className="main-RelationHero-head-page-para7">
          <p
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            data-aos-delay="400"
          >
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
          </p>
        </div>
        <div className="main-RelationHero-head-page-para8">
          <p>
            Aenean vel elit scelerisque mauris. Imperdiet sed euismod nisi porta
            lorem mollis aliquam. Quis vel eros donec ac odio tempor orci
            dapibus ultrices. Elementum eu facilisis sed odio morbi. Nam at
            lectus urna duis convallis convallis tellus id. Feugiat pretium nibh
            ipsum consequat nisl vel. Elementum pulvinar etiam non quam lacus.
            Sit amet porttitor eget.
          </p>
        </div>
        <div className="main-RelationHero-head-page-para8">
          <p>
            Saepe magni aut maxime vel voluptatem. Ab eveniet neque placeat qui
            porro ab minus voluptas. Dicta praesentium neque vero mollitia
            aperiam sed enim. Lacinia quis vel eros donec ac odio.
          </p>
        </div>
      </div>{" "}
    </main>
  </div>
);

export default RelationHero;
