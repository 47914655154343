import React from 'react';
import { Button } from 'antd';
// import video from "../videos/video.mp4";
import './AboutHero.css';
import CountUp from 'react-countup';

const AboutHero = () => (
  <div>
    <main
      data-aos="fade-right"
      data-aos-easing="ease-in-sine"
      className="main-AboutHero-page"
      id="nature"
    >
      <section className="AboutHero-page container">
        <div>
          {/* <div className="hero-video-box order-md-1 order-0">
            <video className="" autoplay="autoplay" loop="true" muted>
              <source src={video} type="video/mp4"></source>
            </video>
          </div> */}

          <div className="AboutHero-page-content text-left">
            <p
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              className="AboutHero-page-para1"
            >
              ABOUT US{' '}
            </p>
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              SERVING THE COMMUNITY IN MARTHANDAM
            </h1>
            <br></br>
          </div>
        </div>
      </section>
    </main>
    <div className="about-router-contentbox mt-4">
      <div className="container">
        <div className="about-router-titlebox text-center">
          <div className="about-router-title">CHRISTHURAJA CATHEDRAL</div>
          <div className="about-router-disc">DIOCESE OF MATHANDAM</div>
        </div>
        <div className="about-router-paras mt-3 mx-auto">
          <p>
            St.Thomas, one of the Apostles of Jesus Christ came to India in 52
            AD. Of the Seven and a half Churches / Communities he established in
            India, the Civil District of Kanyakumari is privileged to having the
            ARAPPALLY at Thiruvithancode. Though there was a Christian community
            in Kanyakumari right from the beginning of the 1st century, it could
            not prevail to be a sizeable Christian community. In the 16 century,
            the Portuguese Missionaries arrived in the Civil District of
            Kanyakumari and as the result of which, a new Christian community
            began to flourish. Later during the British regime in India, the
            Protestant missionaries also started their work among the people in
            this area, which was, in fact, a part of the erstwhile State of
            Travancore.
          </p>
          <p>
            On 20 September 1930 when Metropolitan Archbishop Mar Ivanios
            together with a group of four people (a Bishop, a Priest, a deacon
            and a layman) reunited with the Catholic Church, the Malankara
            Church regain its Catholic communion. On 11 June 1932, by the
            Apostolic Constitution Christo Pastorum Principi, His Holiness Pope
            Pius XI established the Syro Malankara Catholic Hierarchy
            simultaneously erecting the Archdiocese of Trivandrum and the
            Diocese of Tiruvalla. The civil district of Kanyakumari formed part
            of the ecclesiastical territory of the Archdiocese of Trivandrum.
            Mar Ivanios, the pioneer of the Reunion Movement, extended his
            mission work to this area in 1934.
          </p>
          <p>
            With the dedicated service of the Priests and the Religious from
            various Dioceses and Congregations, many parishes and missions of
            the Malankara Church were established. After the demise of Mar
            Ivanios, under the guidance of His Grace Archbishop Benedict Mar
            Gregorios of happy memory, the number of parishes and missions
            increased along with vocation in priesthood and the religious life.
            His Grace Most Rev. Cyril Mar Baselios who succeeded Mar Gregorios,
            assumed office of the Metropolitan Archbishop of Trivandrum.
            Considering the linguistic, social and cultural heritage of the
            people of this region and the vastness of the Metropolitan Eparchy
            of Trivandrum and at the request of His Grace Most Rev. Cyril Mar
            Baselios, His Holiness Pope St. John Paul II, by an Apostolic Decree
            dated 16 December 1996, bifurcated the Metropolitan Eparchy of
            Trivandrum and erected the Eparchy of Marthandam with its head
            quarters at Marthandam, and appointed Most Rev. Lawrance Mar Ephraem
            as its first Bishop. Thus the fourth Eparchy of the Malankara Syrian
            Catholic Church came into existence and His Excellency Most Rev.
            Lawrence Mar Ephraem took charge as its first Bishop. The
            enthronment of His Excellency Mar Ephraem as the Bishop of
            Marthandam took place on 23 January 1997 at the Christuraja
            Cathedral, Marthandam. His Grace Most Rev. Cyril Mar Basalios, the
            Metropolitan Archbishop of Trivandrum officiated the liturgical
            ceremony, and executed the Papal Bull of the erection of the new
            Eparchy of Marthandam.
          </p>
          <p>
            After a prolonged illness, Bishop Lawrence Mar Ephraem slept in Our
            Lord on 8 April1997. On 16 April 1998, His Holiness Pope John Paul
            II appointed Most Rev. Yoohanon Mar Chrysostom as the second Bishop
            of the Eparchy of Marthandam. His Excellency was consecrated Bishop
            on 29 June 1998 and was enthroned as the Bishop of Marthandam on 1
            July 1998.
          </p>
          <p>
            His Beatitude Moran Mor Baselios Cleemis transferred His Excellency
            Most Rev. Yoohanon Mar Chrysostom from the Eparchy of Marthandam and
            appointed him the first Bishop of the new Eparchy of Pathanamthitta
            on 25 January 2010.
          </p>
          <p>
            Having been elected by the Holy Episcopal Synod of the
            Syro-Malankara Catholic Church, and with the assent of His Holiness
            Pope Benedict XVI, on the same day, His Beatitude Moran Mor Baselios
            Cleemis Catholicos appointed Rev. Dr. Vincent Kulapuravilai, as the
            third Bishop of Marthandam. His Beatitude ordained him a bishop with
            the name Vincent Mar Paulos at Mar Ivanios Nagar (Stadium)
            Nalanchira, Trivandrum on 13 March 2010.
          </p>
          <p>
            His Excellency Most Rev. Dr. Vincent Mar Paulos was installed as the
            third Bishop of the Eparchy of Marthandam by His Beatitude Moran Mor
            Baselios Cleemis Catholicos on 14 March 2010 at Christuraja
            Cathedral, Marthandam. Area : 1665 Sq. Kms Population : 1,913,800
            Malankara Catholics : 67,500 Languages : Tamil, English, Malayalam
          </p>
          <p>
            With the assent of the Holy Father Pope Francis and consent of the
            Holy Episcopal Synod, His Beatitude Moran MorBaselios Cardinal
            Cleemis Catholicos, the Father and Head of the Malankara Syrian
            Catholic Church, has expanded the territorial boundaries of the
            Diocese to 20 more civil districts of Tamil Nadu with effect from
            1st October 2020. In order to enhance evangelization and to boost
            humanitarian endeavours in the newly added districts, the priests,
            religious and people are committing themselves placing their trust
            on the great grace and mercy of God.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default AboutHero;
