import './Footer.css';
import React, { useState } from 'react';
import { Button, Input, Form, message } from 'antd';
import { FaBars, FaTimes } from 'react-icons/fa';
import { ArrowRightOutlined } from '@ant-design/icons';
// import img1 from "../images/img1.png";
import { SiFacebook } from 'react-icons/si';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { IoLogoLinkedin } from 'react-icons/io5';
import { useForm } from 'antd/lib/form/Form';
function Footer() {
  const PRIMARY_API =
    'https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev';
  const [form] = useForm();
  const onFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: 'TRAD_TRA_DEV',
      modName: 'CREATE_APPOINTMENT',
      attributeName: 'subscribed',
      attributeValue: [values],
    });
    var adminVerifyRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: adminVerifyRaw,
      redirect: 'follow',
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.statusCode === 200) {
          message.success('Subscribed Successfully');
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="footer-page" id="footer">
        <div className="container">
          {/* <div>
            <div className="footer-logo-section">
              <div className=" align-self-end">
                <img className="img-fluid" src={img1} />
              </div>
              <div className="footer-number">
                <span>+91 844114063</span>
              </div>
            </div>
          </div> */}
          <div className="d-md-flex">
            <div className="col-md-7 d-md-flex">
              <div className="footer-first col-md-5 col-sm-6 ">
                <div className="footer-heading-one ">
                  <h5
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Address
                  </h5>
                </div>
                <div className="footer-para-one">
                  <p
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Christhuraja Cathedral Diocese of Marthandam,<br></br>{' '}
                    Christurajapuram, North Street,
                    <br></br>Marthandam.
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="footer-heading-one">
                  <h5
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    About us
                  </h5>
                </div>
                <div className="footer-para-one">
                  <p
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Pastoral service
                  </p>
                </div>
                <div className="footer-para-one">
                  <p
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Ministries
                  </p>
                </div>
                <div className="footer-para-one">
                  <p
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Events and notices
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="footer-heading-one my-3">
                  <h5
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Connect
                  </h5>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="100"
                  className="footer-para-icon "
                >
                  <span className="px-1">
                    <SiFacebook />
                  </span>
                  <span className="px-3">
                    {' '}
                    <AiFillTwitterCircle />
                  </span>
                  <span>
                    {' '}
                    <IoLogoLinkedin />{' '}
                  </span>
                </div>
              </div>

              <div className="col-md-6 col-11">
                <div className="footer-heading-mail  ">
                  <h3
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    SUBSCRIBE TO GET LATEST
                    <br /> UPDATES & NEWS
                  </h3>
                </div>
                <div>
                  {' '}
                  <div className="fourth-button">
                    <div className=" Fourth-btn ">
                      <Input.Group compact>
                        <Input
                          className="Fourth-page-email-box"
                          defaultValue="Yourmail@gmail.com"
                        />

                        <Button
                          className="Fourth-btn"
                          type="primary"
                          data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                        >
                          Subscribe
                        </Button>
                      </Input.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-content mt-3">
            Copyright © 2022 Christhuraja Cathedral. All Rights Reserverd.
            Powered by{' '}
            <a href="http://www.obotechnology.com" target="_blank">
              Obo Technology Private Limited.
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
