import React, { Component, useState } from "react";
import "../App.css";
import OurblogHead from "./OurblogHead/OurBlogHead";
import Ourbloglast from "./Ourbloglast/Ourbloglast";

export default function Ourblog({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="Ourblog">
      <OurblogHead />
      <Ourbloglast />
    </div>
  );
}
