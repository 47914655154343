import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { Modal } from "antd";
import Home from "./Components/Home";
import AboutUs from "./Components/AboutUs";
import Sermons from "./Components/Sermons";
import Ourblog from "./Components/Ourblog";
import Relationship from "./Components/Relationship";
import Contact from "./Components/Contact";
import Events from "./Components/Events";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import RegisterComponent from "./Components/RegisterComponent/RegisterComponent";
const Wholeapp = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [newValues, setNewValues] = useState({});
  
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/">
          <Route
            exact={true}
            index
            element={
              <Home
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/AboutUs">
          <Route
            exact={true}
            index
            element={
              <AboutUs
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Sermons">
          <Route
            exact={true}
            index
            element={
              <Sermons
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Ourblog">
          <Route
            exact={true}
            index
            element={
              <Ourblog
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Relationship">
          <Route
            exact={true}
            index
            element={
              <Relationship
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Contact">
          <Route
            exact={true}
            index
            element={
              <Contact
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Events">
          <Route
            exact={true}
            index
            element={
              <Events
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route exact path="/Register" element={<RegisterComponent/>}/>
      </Routes>
      <Footer />
    </Router>
  );
};
export default Wholeapp;
