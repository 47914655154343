import React, { Component, useState } from 'react';
import '../App.css';
import AboutHero from './AboutHero/AboutHero';
import AboutLove from './AboutLove/AboutLove';
import AboutPart from './AboutPart/AboutPart';
import Aboutpart1 from './Aboutpart1/Aboutpart1';
import Aboutpart2 from './Aboutpart2/Aboutpart2';
import Aboutpart3 from './Aboutpart3/Aboutpart3';
import Aboutpart4 from './Aboutpart4/Aboutpart4';
import Testimonials from './Testimonials/Testimonials';
export default function AboutUs({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="AboutUs">
      <AboutHero />
      {/* <AboutLove /> */}
      {/* <AboutPart />
      <Aboutpart1 />
      <Aboutpart2 />
      <Aboutpart3 />
      <Aboutpart4 /> */}
      <Testimonials />
    </div>
  );
}
