import React, { Component, useState } from 'react';
import '../App.css';
import Hero from './Hero/Hero';
import Career from './Career/Career';
import About from './About/About';
import Gallery from './Gallery/Gallery';
import Services1 from './Services1/Services1';
import Around from './Around/Around';
import Four from './Four/Four';
import HeroNew from './HeroNew/HeroNew';
export default function Home({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="home">
      <Hero />
      {/* <HeroNew /> */}
      <Career />
      <About />
      <Gallery />
      {/* <Services1 /> */}
      {/* <Around /> */}
      {/* <Four /> */}
    </div>
  );
}
