import './RegisterComponent.css'
import { Space, Table, Button, Drawer, Select, Slider, Radio, Form } from 'antd';
import { useState } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import FirstComponent from './FirstComponent/FirstComponent';
export default function RegisterComponent() {
    const [isDrawerVisible, setIsDrawerVisible] = useState(false)
    const { Option } = Select;
    const [percent, setPercent] = useState([0, 100]);
    const [rates, setRates] = useState([500, 25000])
    const [temp, setTemp] = useState("Premium")
    const [page,setPage]=useState(1)
    const lowest = [
        {
            type: "Premium",
            avail: 250,
            rate: 25000
        },
        {
            type: "Category 1",
            avail: 500,
            rate: 10000
        },
        {
            type: "Category 2",
            avail: 500,
            rate: 5000
        },
        {
            type: "Category 3",
            avail: 1000,
            rate: 2000
        },
        {
            type: "Category 4",
            avail: 1000,
            rate: 1000
        },
        {
            type: "Category 5",
            avail: 2000,
            rate: 500
        }
    ]

    const [realValue, setRealValue] = useState(lowest)

    // const highest = [{
    //     sec: "sec 115 Row D",
    //     type: "Standard Admission",
    //     rate: 10000
    // }
    //     , {
    //     sec: "sec 115 Row D",
    //     type: "Standard Admission",
    //     rate: 10000
    // },
    // {
    //     sec: "sec 115 Row D",
    //     type: "Standard Admission",
    //     rate: 25000
    // },
    // {
    //     sec: "sec 115 Row D",
    //     type: "Standard Admission",
    //     rate: 25000
    // },
    // {
    //     sec: "sec 115 Row D",
    //     type: "Standard Admission",
    //     rate: 25000
    // },]
    // const wholData = [{ name: "Lowest Price", value: lowest }, { name: "Best Seats", value: highest }]
    const increase = () => {
        let newPercent = percent + 20;
        if (newPercent > 100) {
            newPercent = 100;
        }
        setPercent(newPercent);
    };
    const decline = () => {
        let newPercent = percent - 20;
        if (newPercent < 0) {
            newPercent = 0;
        }
        setPercent(newPercent);
    };
    const columns = [
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'Location',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button size='small' type="primary" onClick={() => setIsDrawerVisible(true)}>See Tickets</Button>
                </Space>
            ),
        },
    ];
    const columns1 = [{
        title: 'Category',
        dataIndex: 'type',
        key: 'type',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Available',
        dataIndex: 'avail',
        key: 'avail',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        render: (text) => <a>₹ {text}</a>,
    }
    ]
    const data = [
        {
            key: '1',
            Date: '12-12-2022',
            Location: "christuraja church,marthandam",
        }
    ];
    // const num = (val) => {
    //     var a = []
    //     for (var i = 1; i <= val; i++) {
    //         a.push(i)
    //     }
    //     return a
    // }
    const handleSubmit = () => {
        if (temp) {
      setPage(2)
        }
        else
            return
    }
    const handleChange = (e) => {
        setPercent(e)
        var a = [{ b: 0, c: 500 },
        { b: 17, c: 1000 },
        { b: 34, c: 2000 },
        { b: 51, c: 5000 },
        { b: 68, c: 10000 },
        { b: 84, c: 25000 }
        ]
        if (a.filter(e => (percent[0] <= e.b + 19 && percent[0] >= e.b)).length !== 0) {
            rates[0] = a.filter(e => (percent[0] <= e.b + 19 && percent[0] >= e.b))[0].c
            setRates(rates)
            setRealValue(lowest.filter(e => e.rate >= rates[0] && e.rate <= rates[1]))
        }
        if (a.filter(e => (percent[1] <= e.b + 19 && percent[1] >= e.b)).length !== 0) {
            rates[1] = a.filter(e => (percent[1] <= e.b + 19 && percent[1] >= e.b))[0].c
            setRates(rates)
            setRealValue(lowest.filter(e => e.rate >= rates[0] && e.rate <= rates[1]))
        }
    }
    const firstcomp = {
        rates: rates,
        percent: percent,
        handleChange: handleChange,
        columns1: columns1,
        realValue: realValue,
        setTemp: setTemp,
        handleSubmit: handleSubmit
    }
    const drawercomp = {
        open: isDrawerVisible,
        onClose: () => setIsDrawerVisible(false)
    }
    return <div className='register'>
        <div className='container'>
            <Table columns={columns} dataSource={data} pagination={false} />
        </div>
        <Drawer {...drawercomp}>
            {page===1&&<FirstComponent {...firstcomp} />}
        </Drawer>
    </div>
}