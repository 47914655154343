import React, { Component, useState } from "react";
import "../App.css";
import Eventfirst from "./Eventfirst/Eventfirst";
import EventHero from "./EventHero/EventHero";
import Eventsecond from "./Eventsecond/Eventsecond";

export default function Events({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="Events">
      <EventHero />
      <Eventfirst />
      <Eventsecond />
    </div>
  );
}
