import './FirstComponent.css'
import { Button,Slider,Table } from 'antd'
export default function FirstComponent({rates,percent,handleChange,columns1,realValue,setTemp,handleSubmit}) {
    return <div>
        <div className='d-flex mb-3'>
            <Button className='me-2' >₹ {rates[0]}</Button>
            <Slider range defaultValue={percent} onChange={handleChange}
                tooltip={{ open: false }}
                style={{ width: "100%" }} />
            <Button className='me-2' >₹ {rates[1]}</Button>
        </div>
        <div className='ticket-title my-3'>Available Tickets</div>
        <Table columns={columns1}
            dataSource={realValue}
            pagination={false}
            rowKey={(record) => record.type}
            rowSelection={{ onSelect: (e) => {setTemp(e)}, hideSelectAll: true, type: "radio" }}
        />
        <Button
            className='mt-2'
            style={{ float: "right" }}
            type="primary"
            size="small"
            onClick={() => handleSubmit()}
        >Next</Button></div>
}