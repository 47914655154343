import React, { Component, useState } from "react";

import { Modal, Input, InputNumber, Form, Button, message } from "antd";
import { Link } from "react-router-dom";
import img34 from "../images/img34.jpg";

import { BsClock } from "react-icons/bs";
import { GrLocationPin } from "react-icons/gr";
import "./EventHero.css";
import { useForm } from "antd/es/form/Form";
const EventHero = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [position, setPosition] = useState("");
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const handleOk = () => {
    setModalVisible(false);
  };

  const data = [
    {
      name: "REGISTER NOW",
      location: (
        <div className="Services1-page-location-icon">
          <BsClock />
          <span className="Services1-page-location-content m-md-4">
            Friday 23:39 IST <br></br>Saturday 11:20 ISD
          </span>
        </div>
      ),
      cash: (
        <div className="Services1-page-name-icon">
          <GrLocationPin />
          <span className="Services1-page-name-content m-md-4">
            No 233 Main St. New York, <br />
            United States
          </span>
        </div>
      ),

      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.",
      image: img34,
      apply: (
        <span className="EventHero-btn ">
          <Button className="my-3" type="primary ">
            REGISTER NOW
          </Button>
        </span>
      ),
      Form1: (
        <div>
          {" "}
          <Form className="EventHero-Form1">
            <Form.Item
              className="my-4"
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "Please input your First name!",
                },
              ]}
            >
              <Input placeholder="Your Full Name*" className="phone" />
            </Form.Item>
          </Form>
        </div>
      ),
      Form2: (
        <div>
          {" "}
          <Form className="EventHero-Form2">
            <Form.Item
              className="my-3"
              name="lastname"
              rules={[
                {
                  required: true,
                  message: "Please input your Mail Id!",
                },
              ]}
            >
              <Input placeholder="Your Mail*" className="" />
            </Form.Item>
          </Form>
        </div>
      ),
    },
  ];
  return (
    <div>
      <main className="side-EventHero-page" id="nature">
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="EventHero-page"
        >
          {" "}
        </div>
        <section className="EventHero-page container">
          <div className=""></div>
          <div className="row g-3  content-pack">
            {data.map((e) => (
              <div key={e + Math.random()} className="col-lg-12 ">
                <div className="mx-sm-2 p-sm-5 ">
                  <div className="d-lg-flex justify-content-center align-items center mt-3 ">
                    <div className="mx-4 blog-img col-lg-6">
                      <img className="img-fluid mt-1 " src={`${e.image}`} />
                    </div>

                    <div className="col-lg-6 mx-5 px-5  in-EventHero-box">
                      <div className=" mt-4 EventHero-name">{e.name}</div>
                      <div className="d-flex justify-content-between">
                        <div className=" mt-4 EventHero-location">
                          {e.location}
                        </div>
                        <div className=" mt-4 EventHero-cash ">{e.cash}</div>
                      </div>
                      <div className=" mt-4 EventHero-Form1">{e.Form1}</div>
                      <div className=" mt-4 EventHero-Form2">{e.Form2}</div>
                      <div className=" ">
                        <span className=" mt-4 EventHero-btn  ">{e.apply}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <div></div>
      </main>
    </div>
  );
};
export default EventHero;
