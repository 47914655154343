import React, { Component, useState } from 'react';
import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import img3 from '../images/img3.png';
import img4 from '../images/img4.png';
import img5 from '../images/img5.png';
import './Career.css';

const Career = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [position, setPosition] = useState('');
  const handleOk = () => {
    setModalVisible(false);
  };
  const data = [
    {
      image: img3,
      name: 'Our Parish',
      content:
        'Welcome to CHRISTHURAJA CATHEDRAL DIOCESE OF MATHANDAM CHRISTHURAJAPURTAM NORTH STREET, MARTHANDAM We warmly welcome you to our site and hope you enjoy your visit. If you are new to the Christuraja Cathedral and would like to become a part of our Parish community, we look forward to meeting you at Mass, or please feel free to contact us at any time.',
    },
    {
      image: img4,

      name: 'New to Parish',
      content:
        'Moving into a new area can be quite a challenge, especially finding a new church community in which to worship and feel a part of. We hope our site provides you with assistance as you settle into your new home and surrounds, both geographically and spiritually, including our Service Times, details about our Sacraments, Parish Groups & Volunteers. If you would like to become a part of our Parish, please contact our Parish Office.',
    },
    {
      image: img5,

      name: 'Giving back',
      content: (
        <>
          <p>
            I will give thanks to you, Lord, with all my heart. Psalm 9:1
            <p>
              {' '}
              Your gift supports your parish to share Christ’s message of love
              and salvation with all
            </p>
          </p>
        </>
      ),
    },
  ];
  return (
    <div>
      <main className="side-Career-page" id="nature">
        <section
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="Career-page container"
        >
          <div className=" Career-page-heading text-center">
            <h1>CHRISTHURAJA CATHEDRAL</h1>
          </div>
          <div className=" Career-page-para text-center">
            <p className="">DIOCESE OF MATHANDAM</p>
          </div>

          <div className="d-lg-flex"></div>
          <div className="row g-3  content-pack">
            {data.map((e) => (
              <div key={e + Math.random()} className="col-lg-4 col-md-6 ">
                <div className="mx-2 p-5 h-100 in-carrer-box">
                  <div className="d-flex justify-content-between mt-3 ">
                    <div className="">
                      <div className="my-4">
                        <img className="img-fluid mt-1 " src={`${e.image}`} />
                      </div>
                      <div className=" Career-name">{e.name}</div>
                      <div className=" Career-content">{e.content}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};
export default Career;
