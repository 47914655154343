import React from 'react';
import { Button, Carousel } from 'antd';
import { Link } from 'react-router-dom';
// import video from '../videos/video.mp4';
import './Hero.css';
import CountUp from 'react-countup';
import { AiOutlineMinus } from 'react-icons/ai';
import { REDEIRECT } from '../../Constants/Values';
import img1 from '../images/bg-hero2.jpg';
import img2 from '../images/hero-bg.jpg';
// import img3 from '../images/cathedral.jpg';

const Hero = () => (
  <div className="event-hero-page">
    <div className="container">
      <div className="event-hero-carosel ">
        <Carousel
          infinite={true}
          effect="fade"
          speed={5000}
          autoplay
          dots={false}
          lazyLoad={true}
        >
          <div>
            <img src={img1} className="col-12 px-0" alt="" />
          </div>
          <div>
            <img src={img2} className="singer-img-hero  col-12 px-0" alt="" />
          </div>
        </Carousel>
      </div>
      <div className="event-hero-contentbox d-flex justify-content-center flex-column">
        <div className="event-hero-subcontent">
          <div className="event-hero-subtitle">
            CHRISTURAJA CATHEDRAL, DIOCESE OF MARTHANDAM
          </div>
          <div className="event-hero-subdisc">PROUDLY PRESENTING</div>
        </div>

        <div className="event-hero-maincontent col-md-8 mt-5">
          <div className="event-hero-maintitle">
            JEEVARAJA SRUTHI INNISAI MAMAZHAI
          </div>
          <div className="event-hero-regbtn mt-4">
            <Button
              size="large"
              type="primary"
              onClick={() => (window.location.href = REDEIRECT)}
            >
              BUY TICKETS
            </Button>
            {/* Online Booking Comming Soon */}
          </div>
        </div>

        <div className="event-hero-info mt-5">
          <div className="event-hero-date">Date : 15 Jan 2023, 06:05 PM</div>
          <div className="event-hero-place">
            Venue : Scott Christian College, Nagercoil
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Hero;
