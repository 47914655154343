import React, { Component, useState } from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import img28 from "../images/img28.jpg";

import "./OurblogHead.css";

const OurblogHead = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [position, setPosition] = useState("");
  const handleOk = () => {
    setModalVisible(false);
  };
  const data = [
    {
      head: "Tuesday 13 May, 2022",
      person: "BY JOHN HUNAU DEO",
      name: "CHURCH WAS DOING WHAT HE OFTEN DID WHEN DROPPED AN ORACLE",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.",
      image: img28,
      apply: (
        <span className="OurblogHead-btn ">
          <Button className="" type="primary ">
            Read More
          </Button>
        </span>
      ),
    },
  ];
  return (
    <div>
      <main className="side-OurblogHead-page" id="nature">
        <div className="OurblogHead-page">
          {" "}
          <p
            data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            className=" text-center"
          >
            OUR BLOG{" "}
          </p>
        </div>
        <div className="OurblogHead-page">
          <h1
            className=" text-center"
            data-aos=" fade-right"
            data-aos-easing="ease-in-sine"
          >
            MOST RECENT POST
          </h1>
        </div>
        <section
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="OurblogHead-page container"
        >
          <div className=""></div>
          <div className="row g-3  content-pack">
            {data.map((e) => (
              <div key={e + Math.random()} className="col-lg-12 ">
                <div className="mx-sm-5 p-sm-5 in-OurblogHead-box ">
                  <div className="d-lg-flex justify-content-center align-items center mt-3 ">
                    <div className="mx-4 blog-img col-lg-6">
                      <img className="img-fluid mt-1 " src={`${e.image}`} />
                    </div>
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-between">
                        <div className=" mt-4 OurblogHead-head">{e.head}</div>
                        <div className=" mt-4 OurblogHead-head ">
                          {e.person}
                        </div>
                      </div>

                      <div className=" mt-4 OurblogHead-name">{e.name}</div>
                      <div className="  mt-4 OurblogHead-content">
                        {e.content}
                      </div>
                      <div className=" ">
                        <span className=" mt-4 OurblogHead-btn  ">
                          {e.apply}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};
export default OurblogHead;
