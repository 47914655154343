import React, { Component } from "react";

import img32 from "../images/img32.png";

import "./Eventfirst.css";
const Eventfirst = () => (
  <div>
    <main className="Eventfirst-page" id="nature">
      <section
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className="Eventfirst-page container"
      >
        <div className="  Eventfirst1-page-para">
          <p data-aos="fade-right" data-aos-easing="ease-in-sine">
            UPCOMING EVENT{" "}
          </p>
        </div>
        <div className="Eventfirst-page">
          <h1 data-aos=" fade-right" data-aos-easing="ease-in-sine">
            HOW TO TRULY TRUST SOMEONE
          </h1>
        </div>
        <div className="col-lg-6 col-md-12 m-1  ">
          <div className="main-Eventfirst-head-page-para3">
            <p>
              Aenean vel elit scelerisque mauris. Imperdiet sed euismod nisi
              porta lorem mollis aliquam. Quis vel eros donec ac odio tempor
              orci dapibus ultrices. Elementum eu facilisis sed odio morbi. Nam
              at lectus urna duis convallis convallis tellus id. Feugiat pretium
              nibh ipsum consequat nisl vel. Elementum pulvinar etiam non quam
              lacus. Sit amet porttitor eget.
            </p>
          </div>
          <div className="d-flex justify-content-between ">
            <div>
              <img className="img-fluid mx-1" src={img32}></img>
            </div>
            <div className="main-Eventfirst-head-page-para4">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui
                faucibus in ornare quam viverra orci sagittis eu volutpat
              </p>
            </div>
          </div>
          <div className="main-Eventfirst-head-page-para5">
            <p>
              Aenean vel elit scelerisque mauris. Imperdiet sed euismod nisi
              porta lorem mollis aliquam. Quis vel eros donec ac odio tempor
              orci dapibus ultrices. Elementum eu facilisis sed odio morbi. Nam
              at lectus urna duis convallis convallis tellus id. Feugiat pretium
              nibh ipsum consequat nisl vel. Elementum pulvinar etiam non quam
              lacus. Sit amet porttitor eget.
            </p>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default Eventfirst;
