import React, { Component } from "react";
import img5 from "../images/img5.png";
import "./SermonFour.css";
import { Carousel } from "antd";
import { BsClock } from "react-icons/bs";
import { GrLocationPin } from "react-icons/gr";
import img25 from "../images/img25.jpg";
import img26 from "../images/img26.jpg";
import img27 from "../images/img27.jpg";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { Input, Button, Drawer, Form, message } from "antd";
import { Link } from "react-scroll";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
const SermonFour = ({ setNewMessage, setModalVisible }) => {
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const data = [
    {
      type: "20 JULY",
      name: "Upcoming Event",
      sermons: "WATCH AND LISTEN TO OUR SERMONS",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      location: (
        <div className="SermonFour-page-location-icon">
          <BsClock />
          <span className="SermonFour-page-location-content m-md-1">
            Friday 23:39 IST <br></br>Saturday 11:20 ISD
          </span>
        </div>
      ),
      cash: (
        <div className="SermonFour-page-name-icon">
          <GrLocationPin />
          <span className="SermonFour-page-name-content m-md-1">
            No 233 Main St. New York,
            <br /> United States
          </span>
        </div>
      ),
    },
    {
      type: "20 JULY",
      name: "Upcoming Event",
      sermons: "WATCH AND LISTEN TO OUR SERMONS",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      location: (
        <div className="SermonFour-page-location-icon">
          <BsClock />
          <span className="SermonFour-page-location-content m-md-1">
            Friday 23:39 IST <br></br>Saturday 11:20 ISD
          </span>
        </div>
      ),
      cash: (
        <div className="SermonFour-page-name-icon">
          <GrLocationPin />
          <span className="SermonFour-page-name-content m-md-1">
            No 233 Main St. New York,
            <br /> United States
          </span>
        </div>
      ),
    },
    {
      type: "20 JULY",
      name: "Upcoming Event",
      sermons: "WATCH AND LISTEN TO OUR SERMONS",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      location: (
        <div className="SermonFour-page-location-icon">
          <BsClock />
          <span className="SermonFour-page-location-content m-md-1">
            Friday 23:39 IST <br></br>Saturday 11:20 ISD
          </span>
        </div>
      ),
      cash: (
        <div className="SermonFour-page-name-icon">
          <GrLocationPin />
          <span className="SermonFour-page-name-content m-md-1">
            No 233 Main St. New York, <br />
            United States
          </span>
        </div>
      ),
    },
    {
      type: "20 JULY",
      name: "Upcoming Event",
      sermons: "WATCH AND LISTEN TO OUR SERMONS",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      location: (
        <div className="SermonFour-page-location-icon">
          <BsClock />
          <span className="SermonFour-page-location-content m-md-1">
            Friday 23:39 IST <br></br>Saturday 11:20 ISD
          </span>
        </div>
      ),
      cash: (
        <div className="SermonFour-page-name-icon">
          <GrLocationPin />
          <span className="SermonFour-page-name-content m-md-1">
            No 233 Main St. New York, <br />
            United States
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="SermonFour-page-main-section">
      <div
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className=" main-SermonFour-page container"
      >
        <div className="SermonFour-page-para2 text-center">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            VIEW ALL EVENTS
          </h1>
        </div>
        <div className="row g-0 text-center main-SermonFour-page ">
          {data.map((e) => (
            <Link
              to="/"
              key={e + Math.random()}
              className="col-lg-3  col-md-6 "
            >
              <div className="mx-3 px-4 in-box my-2">
                <div className=" mt-4">
                  <div className="">
                    <div className="mb-0 SermonFour-type text-end">
                      {e.type}
                    </div>
                    <div className=" SermonFour-name">{e.name}</div>
                    <div className=" mt-4 SermonFour-sermons">{e.sermons}</div>
                    <div className="mt-4 SermonFour-content">{e.content}</div>
                    <div className="  mt-4 SermonFour-page-name-icon text-start">
                      {e.location}
                    </div>
                    <div className="  mt-4 SermonFour-page-name-icon text-start">
                      {e.cash}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SermonFour;
