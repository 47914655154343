import React, { Component } from 'react';
import { Button } from 'antd';
import img7 from '../images/img7.jpg';
import img8 from '../images/img8.jpg';
import img10 from '../images/img10.jpg';
import { BsArrowRight } from 'react-icons/bs';

import './About.css';
const About = () => (
  <div>
    <main
      data-aos="fade-right"
      data-aos-easing="ease-in-sine"
      className="main-about-page"
      id="nature"
    >
      <section className="about-page container">
        {/* <div className=" about-page-para1 text-center">
          <p className="text-center">SUB-HEADLINE</p>
        </div> */}
        <div className=" about-page-heading text-center">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            LOVE AND COMPASSION
          </h1>
        </div>
        <div className=" about-page-para2 text-center">
          <p className="text-center">
            The term “love” is quoted approximately four hundred times in the
            Bible, making it one of the most frequently used terms in the entire
            corpus of Sacred Scripture. Love has been the topic of some of the
            world’s greatest poems, songs, theater, books, and art from every
            age. In Catholic circles, love has also been the topic of theology
            and spirituality—and with good reason, for love is an expression of
            the heart and a powerful emotion.
            <br></br>
            Compassion means to suffer with, in other words to empathize with
            the suffering of another, to the point of taking it upon oneself.
            Jesus is like this: he suffers together with us, he suffers with us,
            he suffers for us. And the sign of this compassion is the healing of
            countless people he performed.
          </p>
        </div>
        <div className="text-center my-3 ">
          <span className="about-btn text-left  ">
            <Button
              // data-aos="fade-right"
              // data-aos-easing="ease-in-sine"
              // data-aos-delay="200"
              type="primary "
            >
              {' '}
              Read More
            </Button>
          </span>
        </div>
        <div className="d-md-flex justify-content-center">
          <div className="col-12 col-lg-3 col-md-4 about-page-image1">
            <div className="mx-2">
              <img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-delay="200"
                className="img-fluid"
                src={img8}
              ></img>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-4 about-page-image2 my-5">
            <div className="mx-2">
              <img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-delay="200"
                className="img-fluid"
                src={img7}
              ></img>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 about-page-image3 my-3">
            <div className="mx-2 ">
              <img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-delay="200"
                className="img-fluid"
                src={img10}
              ></img>
            </div>
          </div>
        </div>
        {/* <div className=" about-page-para-bottom1 text-center">
          <p className="text-center">OUR MISSION & VISION</p>
        </div>
        <div className=" about-page-heading-bottom1 text-center">
          <h1>CELEBRATE WITH US</h1>
        </div>
        <div className=" about-page-para-bottom2 text-center">
          <p className="text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor <br />
            incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div className="text-center">
          <span className="about-page-para-bottom3">Read More</span>
          <span className=" about-arrow px-2">
            <BsArrowRight />
          </span>
        </div> */}
      </section>
    </main>
  </div>
);

export default About;
