import React, { Component, useState } from "react";
import "../App.css";

import SermonsHero from "./SermonsHero/SermonsHero";
import SermonServices from "./SermonServices/SermonServices";
import SermonFour from "./SermonFour/SermonFour";
export default function Home({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="sermons">
      <SermonsHero />
      <SermonServices />
      <SermonFour />
    </div>
  );
}
