import './Navbar.css';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import logo from '../images/logo.jpg';
// import logo from '../images/logo.png';
function Navbar() {
  const [icon, setIcon] = useState(false);
  const [page, setPage] = useState('Contact');
  const handleClick = () => {
    setIcon(!icon);
  };
  const closeSideDrawer = () => {
    setIcon(false);
  };

  const Menus = [
    { li: '/', title: 'Home' },
    { li: '/AboutUs', title: 'About Us' },
    // { li: '/#', title: 'Pastoral Service' },
    // { li: '/#', title: 'Ministries' },

    // { li: '/#', title: 'Events and Notices' },
  ];
  // const Menus = [
  //   { li: "/", title: "Home" },
  //   { li: "/AboutUs", title: "About Us" },
  //   { li: "/Sermons", title: "Sermon" },
  //   { li: "/Ourblog", title: "Blog" },
  //   { li: "/Relationship", title: "Relationship" },
  //   { li: "/Events", title: "Events" },
  // ];

  const ToggleNavBar = () => {
    let element = document.getElementsByClassName('navbar-toggler')[0];
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <div className="navbar-logo col-md-2 col-sm-5 col-7">
            <img className="img-fluid" src={logo} />
          </div>
          <Button
            className="toggler-nav-btn"
            type="primary"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </Button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={`navbar-nav ms-auto `}>
              {Menus.map((e, i) => (
                <li>
                  <NavLink
                    end
                    to={e.li}
                    className={`nav-links ${({ isActive }) =>
                      isActive ? 'active' : ''}`}
                    onClick={() => ToggleNavBar()}
                  >
                    {e.title}
                  </NavLink>
                </li>
              ))}
              <li className="disabled-nav-links">Pastoral Service</li>
              <li className="disabled-nav-links">Ministries</li>
              <li className="disabled-nav-links">Events and Notices</li>
              <span className="nav-btn ">
                <Button type="primary" key="console">
                  Contact Us
                  {/* <Link to="/#">Contact Us</Link> */}
                </Button>
              </span>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
