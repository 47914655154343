import React from "react";
import { Button } from "antd";
// import video from "../videos/video.mp4";
import "./SermonsHero.css";
import CountUp from "react-countup";
import { AiOutlineMinus } from "react-icons/ai";
const SermonsHero = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="main-SermonsHero-page"
      id="nature"
    >
      <section className="SermonsHero-page container">
        <div>
          {/* <div className="hero-video-box order-md-1 order-0">
            <video className="" autoplay="autoplay" loop="true" muted>
              <source src={video} type="video/mp4"></source>
            </video>
          </div> */}

          <div className="SermonsHero-page-content text-left">
            <p
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              className="SermonsHero-page-para1"
            >
              SERMON{" "}
            </p>
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              TAKE A PART IN OUR SERMON
            </h1>
            <br></br>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default SermonsHero;
